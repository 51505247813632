@import '../../globals/mixins';

.staticContent {
  // img:not(.imgLoaded) {
  // 	min-height: 64px;
  // 	background-image: url("/assets/icons/loading.svg");
  // 	background-position: center center;
  // 	background-repeat: no-repeat;
  // }
  button {
    &.auto {
      width: 100%;
      @include screen-s {
        width: initial;
      }
    }
  }

  &.what-is-venueScout-anchor-1 {
    padding-top: $size-xl;
    & > div {
      // Col
      & > div {
        & > div {
          float: left;
          width: 100%;

          &:nth-child(2) {
            max-height: 750px;
            overflow: hidden;
          }

          @include screen-s {
            width: 50%;
            padding-right: $size-sm;
            &:nth-child(2) {
              max-height: none;
              padding-right: 0;
            }
          }

          @include screen-m {
            width: 60%;
            &:nth-child(2) {
              width: 40%;
            }
          }

          @include screen-l {
            width: 75%;
            padding-right: $size-xl;
            &:nth-child(2) {
              width: 25%;
            }
          }
        }
      }
    }
  }
  &.what-is-venueScout-anchor-2 {
    padding-top: $size-xl;
    & > div {
      // Col
      & > div {
        & > div {
          float: left;
          width: 100%;

          @include screen-s {
            width: 50%;
            &:nth-child(2) {
              padding-left: $size-sm;
            }
          }

          @include screen-m {
            width: 40%;
            &:nth-child(2) {
              width: 60%;
            }
          }

          @include screen-l {
            width: 25%;
            &:nth-child(2) {
              width: 75%;
              padding-left: $size-xl;
            }
          }
        }
      }
    }
  }

  &.contacts,
  &.members {
    figure {
      border-top: 1px solid $color-subtl2;
      display: flex;
      min-height: 150px;
      align-items: flex-end;
      justify-content: space-between;
      padding: $font-size 0;
      padding-right: $size-s;
      figcaption {
        width: 100%;
        a {
          word-break: break-all;
          @include font-size-small;
        }
      }
    }

    p {
      figure {
        border-top: none;
        border-bottom: 1px solid $color-subtl2;
        align-items: center;
        min-height: 110px;
        padding: 0 $size-xs 0 0;
        figcaption {
          padding: $font-size 0;
        }
      }
    }
    &.members {
      p {
        padding-right: $size-xs;
      }
    }
  }

  &.partners {
    & > div {
      // Col
      & > div {
        & > div {
          border-bottom: 1px solid $color-subtl2;
          padding: $size-s 0;
          margin: $size-s 0;
          figure {
            & > a {
              border: 1px solid $color-subtl1;
              @include rounded;
              padding: $size-s;
              display: inline-block;
              margin: $size-xs 0;
              &:hover {
                border-color: $color-dark;
              }
            }
          }
        }
      }
    }
  }

  &.faq {
    ol,
    ul {
      font-size: unset;
    }
    ol {
      @include font-size-p;
      ::marker {
        font-weight: bolder;
      }

      ul,
      li {
        font-size: inherit;
      }
      & > li {
        margin-bottom: 1em;
      }
      & > h2 {
        margin-left: -2.5em;
      }

      @include screen-m {
        & > h2 {
          margin-left: -1.8em;
        }
      }

      @include screen-xl {
        & > h2 {
          margin-left: -1.5em;
        }
      }
    }
  }

  &:not(.full) {
    & > div {
      // col
      padding-bottom: $size-ss;

      & > div {
        // margin-bottom: $font-size-xl;
        & > *:not(svg) {
          margin: 0 0 $size-s;
          white-space: break-spaces;
          -webkit-column-break-inside: avoid;
          break-inside: avoid;
        }
        @include screen-m {
          column-count: 2;
        }
        // @include screen-xl {
        // 	column-count: 3;
        // }
      }
    }

    &.small > div {
      // col
      & > div {
        @include screen-s {
          column-count: 2;
        }
        @include screen-xl {
          column-count: 3;
        }
      }
    }

    &.colBreak {
      & > div {
        // col
        & > div {
          & > *:not(svg) {
            -webkit-column-break-inside: unset;
            break-inside: unset;
          }
        }
      }
    }
  }

  & > div {
    // col
    ul,
    li {
      margin: revert;
      padding: revert;
      list-style: revert;
      list-style-type: revert;
    }
    &.img {
      margin-top: $font-size-xl;
      margin-bottom: $font-size-xl;
    }
  }

  &.center {
    & > div {
      // col
      text-align: center;
      h1,
      h2,
      h3,
      p,
      & > div {
        text-align: center;
      }
    }
  }

  &.hero {
    &:not(.subh) {
      @include font-sizes-hero;
    }
    &.subh {
      @include font-sizes-hero-h2;
    }

    h2 {
      margin-bottom: 0.5em;
    }
    & > div {
      // col
      &.img {
        margin-top: 4em;
        margin-bottom: 4em;
      }
    }
  }
}
