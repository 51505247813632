@import './vars';

@mixin screen-s() {
  @media (min-width: $screen-s) {
    @content;
  }
}

@mixin screen-sm() {
  @media (min-width: $screen-sm) {
    @content;
  }
}

@mixin screen-m() {
  @media (min-width: $screen-m) {
    @content;
  }
}

@mixin screen-l() {
  @media (min-width: $screen-l) {
    @content;
  }
}

@mixin screen-xl() {
  @media (min-width: $screen-xl) {
    @content;
  }
}

@mixin screen-xxl() {
  @media (min-width: $screen-xxl) {
    @content;
  }
}

@mixin screen-larger-grid() {
  @media (min-width: $screen-larger-grid) {
    @content;
  }
}

@mixin canHover() {
  @media (hover: hover) {
    @content;
  }
}

@mixin font-sizes-hero-h2() {
  h1 {
    @include font-size-h2;
  }
  h2 {
    @include font-size-hero;
  }
  p {
    @include font-size-h2;
  }
}

@mixin font-sizes-hero() {
  h1 {
    @include font-size-hero;
  }
  h2 {
    @include font-size-h1;
  }
  p {
    @include font-size-h2;
  }
}

@mixin font-size-hero() {
  font-size: $font-size-m;

  @include screen-m() {
    font-size: $font-size-xxl;
  }

  @include screen-xl() {
    font-size: $font-size-xxxl;
  }
}

@mixin font-size-h1 {
  font-size: $font-size-m;
  font-weight: 400;

  @include screen-m {
    font-size: $font-size-l;
  }

  @include screen-xl {
    font-size: $font-size-xl;
  }
}

@mixin font-size-h2 {
  font-size: $font-size-s;
  font-weight: 400;

  @include screen-m {
    font-size: $font-size-sm;
  }

  @include screen-xl {
    font-size: $font-size-m;
  }
}

@mixin font-size-p {
  font-size: $font-size-ss;
  font-weight: normal;

  @include screen-m {
    font-size: $font-size;
  }

  @include screen-xl {
    font-size: $font-size-s;
  }
}

@mixin font-size-small {
  font-size: $font-size-xxs;

  @include screen-m {
    font-size: $font-size-xs;
  }
}

@mixin canHover() {
  @media (hover: hover) {
    @content;
  }
}

@mixin flex-center() {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-left-center() {
  display: inline-flex;
  align-items: center;
  justify-content: left;
}
@mixin flex-left-bottom() {
  display: inline-flex;
  align-items: baseline;
  justify-content: left;
}

@mixin no-scrollbar() {
  &::-webkit-scrollbar {
    display: none;
  }
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@mixin no-list() {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
}

@mixin no-select() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@mixin no-drag() {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

@mixin rounded() {
  border-radius: $size-xs;
}

@mixin offset($size: $size-s) {
  margin-left: calc(#{$size} / -2);
  position: relative;
  // padding: $size-xs 0;
  width: calc(100% + #{$size});
}

@mixin widthByCount($count) {
  $divisor: 1;
  @if ($count == '6') {
    $divisor: 6;
  } @else if ($count == '5') {
    $divisor: 5;
  } @else if ($count == '4') {
    $divisor: 4;
  } @else if ($count == '3') {
    $divisor: 3;
  } @else if ($count == '2') {
    $divisor: 3;
  } @else {
    $divisor: 1;
  }
  width: calc(100% / #{$divisor});
}

@function size($factor: 1, $base: 4) {
  $result: $base;
  @for $_ from 1 through $factor {
    $result: $factor * $base;
  }
  @return $result * 1px;
}

@mixin element-height-3-4() {
  padding-bottom: calc(100% / (3 / 4));
}
@mixin element-height-4-3() {
  padding-bottom: calc(100% / (4 / 3));
}
@mixin element-height-1-1() {
  padding-bottom: calc(100%);
}
@mixin element-height-9-3() {
  padding-bottom: calc(100% / (9 / 3.5));
}
@mixin element-height-16-9() {
  padding-bottom: calc(100% / (16 / 9));
}

@mixin rel-width-third() {
  width: calc(33.333333%);
  margin: $size-xs;
}

@mixin rel-width-s() {
  width: calc(25%);
  margin: $size-xs;

  // @include screen-m() {
  // 	min-width: calc(23% - #{$size-xs} * 5);
  // }
  // @include screen-l() {
  // 	min-width: calc(((100vw + #{$size-s})/4) - #{$size-xs} * 4);
  // }
  // @include screen-larger-grid() {
  // 	min-width: 0;
  // 	width: calc((#{$screen-larger-grid} / 4) - #{$size-s});
  // }
}

@mixin float-width-xs($float: left, $margin: $size-xs) {
  float: $float;
  @if $float == none {
    display: inline-block;
  }
  margin: $margin;
  width: calc(12.5% - (#{$margin} * 2));
}

@mixin float-width-ss($float: left) {
  float: $float;
  @if $float == none {
    display: inline-block;
  }
  margin: $size-xs;
  width: calc(20% - #{$size-s});
}

@mixin float-width-s($float: left) {
  float: $float;
  @if $float == none {
    display: inline-block;
  }
  margin: $size-xs;
  width: calc(25% - #{$size-s});
}

@mixin float-width-height-s($float: left) {
  float: $float;
  @if $float == none {
    display: inline-block;
  }
  margin: $size-xs;
  width: calc(25% - #{$size-s});
  padding-bottom: calc(25% - #{$size-s});
}

@mixin float-width-l($float: left) {
  float: $float;
  @if $float == none {
    display: inline-block;
  }
  margin: $size-xs;
  width: calc(33.333333% - #{$size-s});
}

@mixin float-width-m($float: left) {
  float: $float;
  @if $float == none {
    display: inline-block;
  }
  margin: $size-xs;
  width: calc(50% - #{$size-s});
}

@mixin float-width-full($float: left) {
  float: $float;
  @if $float == none {
    display: inline-block;
  }
  margin: $size-xs;
  width: calc(100% - #{$size-s});
}

@mixin float-width-responsive() {
  @include float-width-full;
  @include screen-m {
    @include float-width-m;
  }
  @include screen-l {
    @include float-width-l;
  }
  @include screen-xl {
    @include float-width-s;
  }
}

@mixin element-width-m() {
  min-width: calc(50vw - #{$size-xs} * 3);

  @include screen-m() {
    min-width: calc(50vw - #{$size-xs} * 5);
  }
  @include screen-l() {
    min-width: calc(((100vw + #{$size-s}) / 4) - #{$size-xs} * 4);
  }
  @include screen-larger-grid() {
    min-width: 0;
    width: calc((#{$screen-larger-grid} / 4) - #{$size-s});
  }
}

@mixin shadow-s() {
  -webkit-box-shadow: 0 0 $size-s 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 $size-s 0 rgba(0, 0, 0, 0.2);
}

@mixin shadow-m() {
  -webkit-box-shadow: 0 0 $size-m 0 rgba(0, 0, 0, 0.1444);
  box-shadow: 0 0 $size-m 0 rgba(0, 0, 0, 0.1444);
}

@mixin placeholder-color($color) {
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color;
  }

  &:-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color;
  }
}

@mixin placeholder-size($size) {
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: $size;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: $size;
  }

  &:-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: $size;
  }
}

@mixin slideable() {
  width: calc(100vw - #{$size-s});
  display: inline-block;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  white-space: nowrap;
}

@mixin bg-blur() {
  backdrop-filter: blur($size-s);
  // -webkit-backdrop-filter: blur(15px);

  // -webkit-transition: 1s -webkit-filter linear;
  // -moz-transition: 1s -moz-filter linear;
  // -moz-transition: 1s filter linear;
  // -ms-transition: 1s -ms-filter linear;
  // -o-transition: 1s -o-filter linear;
  // transition: 1s filter linear, 1s -webkit-filter linear;
}

@mixin autoscroll() {
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

@mixin transition($prop) {
  -webkit-transition: $prop $speed-default;
  transition: $prop $speed-default;
}

@mixin transition-all() {
  -webkit-transition: all $speed-default;
  transition: all $speed-default;
}

@mixin transition-all-bounce() {
  -webkit-transition: all $speed-default cubic-bezier(0.175, 0, 0, 1.275);
  transition: all $speed-default cubic-bezier(0.175, 0, 0, 1.275); //cubic-bezier(0.175, 0.885, 0.32, 1.275)
}

@mixin transition-all-cubic() {
  -webkit-transition: all $speed-default cubic-bezier(0, 1, 0, 1);
  transition: all $speed-default cubic-bezier(0, 1, 0, 1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@mixin fade-in {
  // animation: 1s ease-out 2s 4 scaleXin;
  animation: 1s ease-out 0.2s fadeIn;
  // animation-duration: 1s; /* the duration of the animation */
  // animation-timing-function: ease-out; /* how the animation will behave */
  // animation-delay: .3s; /* how long to delay the animation from starting */
  // animation-iteration-count: 1; /* how many times the animation will play */
  // animation-name: scaleXin; /* the name of the animation we defined above */
}

@keyframes scaleXin {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

@mixin scaleX-in() {
  // animation: 1s ease-out 2s 4 scaleXin;
  animation: 1s ease-out 0.2s scaleXin;
  transform-origin: left center;
  // animation-duration: 1s; /* the duration of the animation */
  // animation-timing-function: ease-out; /* how the animation will behave */
  // animation-delay: .3s; /* how long to delay the animation from starting */
  // animation-iteration-count: 1; /* how many times the animation will play */
  // animation-name: scaleXin; /* the name of the animation we defined above */
}

@mixin loadingBg() {
  background-image: url('/assets/icons/loading.svg');
  background-position: center center;
  background-repeat: no-repeat;
  & > div {
    opacity: 0.1;
  }
}

@mixin loading() {
  opacity: 0.2;
  cusor: pointer;
  pointer-events: none;
}

@mixin clearfix {
  &::after {
    content: '';
    clear: both;
    display: table;
  }
}

@mixin detailPageHeader {
  & > div {
    // Col
    & > button {
      margin: 0 $size-s $size-s 0;
    }

    & > p {
      display: inline-block;
      width: 100%;
    }

    @include screen-m {
      & > button {
        margin: 0 $size-s $size-l 0;
      }
      & > p {
        display: unset;
        width: auto;
      }
    }
  }
}

@mixin fade-top-bottom() {
  &::before {
    position: absolute;
    content: ' ';
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 30%, white 100%);
    border-top-left-radius: $size-xs;
    border-top-right-radius: $size-xs;
    width: 100%;
    left: 0;
    top: 0;
    height: $size-m;
    z-index: 2;
  }

  &::after {
    position: absolute;
    content: ' ';
    background: linear-gradient(0deg, white 20%, rgba(255, 255, 255, 0) 100%);
    // background: linear-gradient(0deg, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 100%);
    border-bottom-left-radius: $size-xs;
    border-bottom-right-radius: $size-xs;
    width: 100%;
    left: 0;
    bottom: 0;
    height: $size-l;
    z-index: 2;
  }
}

@mixin dot-navigation() {
  @include rounded;
  background: $color-white;
  border: 1px solid $color-subtl1;
  bottom: $size-xxs;
  left: 50%;
  padding: 0 $size-xs;
  right: auto;
  transform: translateX(-50%);

  li {
    // &:only-child {
    //   display: none;
    // }
    margin: calc(#{$size-xs} / 2);
    display: inline-flex;
    padding: 0;

    & > button {
      &::before {
        content: '⬤';
      }
      font-size: 8px;
      border: none;
      background: none;
      color: $color-grey;
      &:hover {
        color: $color-blue;
      }
      &.active {
        pointer-events: none;
        color: $color-subtl3;
      }
    }

    &.active,
    &.react-multi-carousel-dot--active {
      & > button {
        pointer-events: none;
        color: $color-subtl3;
      }
    }
  }
}
