$color-blue: #006cff;
$color-gold: #d6c3ae;
$color-green: #60d3bd;
$color-red: #ff8089;
$color-dark: #001640;
$color-grey: #7f898e;
$color-subtl1: #aebdc4;
$color-subtl2: #dcecf4;
$color-subtl3: #ecf0f2;
$color-white: #fff;
$color-gradient: linear-gradient(180deg, $color-blue 0%, $color-green 100%);
$color-gradient-btt: linear-gradient(180deg, $color-blue 0%, $color-green 100%);
$color-gradient-ttb: linear-gradient(0deg, $color-blue 0%, $color-green 100%);
$color-gradient-ltr: linear-gradient(90deg, $color-blue 0%, $color-green 100%);
$color-gradient-rtl: linear-gradient(90deg, $color-green 0%, $color-blue 100%);
//$color-gradient-r2l: transparent linear-gradient(90deg, $color-green 0%, $color-blue 100%) 0% 0% no-repeat padding-box;
$size-xxxs: 2px;
$size-xxs: 4px;
$size-xs: 8px;
$size-ss: 12px;
$size-s: 16px;
$size-sm: 24px;
$size-m: 32px;
$size-ml: 40px;
$size-l: 48px;
$size-ll: 56px;
$size-xl: 64px;
$size-xxl: 72px;
$size-xxxl: 80px;
$size-xxxxl: 96px;

$font-size-xxxs: 0.625em;
$font-size-xxs: 0.6875em;
$font-size-xs: 0.8125em;
$font-size-ss: 0.875em;
$font-size: 1em;
$font-size-s: 1.125em;
$font-size-sm: 1.375em;
$font-size-m: 1.5em;
$font-size-ml: 1.625em;
$font-size-l: 1.75em;
$font-size-xl: 2em;
$font-size-xxl: 2.25em;
$font-size-xxxl: 3em;

$screen-s: 480px;
$screen-sm: 640px;
$screen-m: 768px;
$screen-l: 1024px;
$screen-xl: 1280px;
$screen-xxl: 1600px;
$grid-max-width: 1360px;
$grid-half-width: 680px;
$grid-quarter-width: 340px;
$screen-larger-grid: calc(#{$grid-max-width} + (#{$size-s} * 10));

$speed-default: 0.3s;

$gallery-height-min: 200px;
$gallery-height-max: 368px;
// Carousel items swipe width
$diff-single-s: calc(-1 * (((100vw + #{$size-s}) / 2) - #{$size-s}));
$diff-single-m: calc(-1 * (((100vw + #{$size-s}) / 2) - #{$size-m}));
$diff-single-l: calc(-1 * (((100vw + #{$size-s}) / 4) - #{$size-s}));
$diff-single-xl: calc(-1 * (#{$screen-larger-grid} / 2));
$diff-single-xxl: calc(-1 * (#{$screen-larger-grid} / 4));

$diff-even-s: calc(-2 * (((100vw + #{$size-s}) / 2) - #{$size-s}));
$diff-even-m: calc(-2 * (((100vw + #{$size-s}) / 2) - #{$size-m}));
$diff-even-l: calc(-2 * (((100vw + #{$size-s}) / 4) - #{$size-s}));
$diff-even-xl: calc(-4 * (#{$screen-larger-grid} / 2));
$diff-even-xxl: calc(-4 * (#{$screen-larger-grid} / 4));

$diff-odd-s: calc(-1 * (((100vw + #{$size-s}) / 2) - #{$size-s}));
$diff-odd-m: calc(-1 * (((100vw + #{$size-s}) / 2) - #{$size-m}));
$diff-odd-l: calc(-1 * (((100vw + #{$size-s}) / 4) - #{$size-s}));
$diff-odd-xl: calc(-3 * (#{$screen-larger-grid} / 2));
$diff-odd-xxl: calc(-3 * (#{$screen-larger-grid} / 4));
